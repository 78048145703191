import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UnauthenticatedRoute, AuthenticatedRoute } from 'boss-ui';
import Login from './containers/Login';
import Home from './containers/Home';
import NotAllowed from './containers/NotAllowed';
import NotFound from './containers/NotFound';
import Account from './containers/Account';
import Logout from './containers/Logout';
import Scenarios from './containers/Scenarios';
import Scenario from './containers/Scenario';
import Users from './containers/Users';
import Events from './containers/Events';
import LoginSuccess from './containers/SamlPostLogin';
import { useAppContext } from './libs/context-lib';
import {
  validateCognitoUserExists,
  validateUserSession,
  validateDynamoUserExists,
} from './libs/user-lib';

export default function Routes() {
  const { state } = useAppContext();

  const isUserSessionValid = () => validateUserSession(state.user);
  const isCognitoUserValid = () => validateCognitoUserExists(state.user);
  const isBossUserValid = () => validateDynamoUserExists(state.user);
  return (
    <Switch>
      <AuthenticatedRoute
        path="/account"
        validateSession={isCognitoUserValid}
        fallbackRoute="/login"
      >
        <AuthenticatedRoute
          path="/account"
          validateSession={isBossUserValid}
          fallbackRoute="/firstLogin"
        >
          <Account />
        </AuthenticatedRoute>
      </AuthenticatedRoute>
      <UnauthenticatedRoute validateSession={isUserSessionValid} path="/login" exact>
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/logout" exact validateSession={isCognitoUserValid}>
        <Logout />
      </AuthenticatedRoute>
      <Route path="/successLogin">
        <LoginSuccess />
      </Route>
      <AuthenticatedRoute path="/scenarios" validateSession={isUserSessionValid} exact>
        <Scenarios />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/scenarios/:scenarioId" validateSession={isUserSessionValid}>
        <Scenario />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/users" validateSession={isUserSessionValid}>
        <Users />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/events" validateSession={isUserSessionValid}>
        <Events />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/" validateSession={isUserSessionValid} exact>
        <Home />
      </AuthenticatedRoute>
      <Route path="/not-allowed" exact>
        <NotAllowed />
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
