export const defaultMenuItems = [
  { path: '/scenarios', id: 'menu-scenarios', label: 'scenarios', exact: true },
  {
    path: '/users',
    id: 'menu-users',
    label: 'users',
  },
  { path: '/events', id: 'menu-events', label: 'events' },
];
export const BOTS_TYPE = {
  SOC: 'SOC',
  NOC: 'NOC',
};
export const TEAM_VISIBILITY = { PUBLIC: 'PUBLIC', PRIVATE: 'PRIVATE' };
export const TEAM_ACCESSIBILITY = { OPEN: 'OPEN', CLOSED: 'CLOSED' };
export const TEAM_MEMBER_ROLE = { CAPTAIN: 'CAPTAIN', REGULAR: 'MEMBER' };
export const USER_TEAM_STATE = {
  AVAILABLE: 'available',
  INVITED: 'invited',
};
export const USER_INVITE_STATE = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
};
export const TEAM_NAME_REGEXP = /^(?! )[a-zA-Z0-9 _]{2,29}[^\W]$/;
export const DISPLAY_NAME_REGEXP = /^(?! )[a-zA-Z0-9 _]{2,29}[^\W]$/;
export const DEFAULT_FORM_INPUT_TYPE = 'input';
export const INPUT_FORM_TYPE_TEXTAREA = 'textarea';
export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  ONE_HUNDRED: 100,
};
export const USER_ROLES = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  PLAYER: 'PLAYER',
  MEMBER: 'MEMBER',
};
export const DISPLAY_QUESTIONS_MODE = { GRID: 'GRID', LIST: 'LIST' };
export const REMOVE_QUESTION_CONFIRMATION_DIALOG = 'Are you sure on removing this question?';
export const MENU_BAR_HEIGHT = 50;
export const NO_DATA_IN_LIST_MESSAGE = 'No data';
export const RESOURCES_COLORS = ['#61ADA1', '#81A2BE', '#E5C07B', '#A088A4'];
export const RESOURCE_TYPE = {
  SERVER: 'server',
  URL: 'url',
  CREDENTIALS: 'credentials',
};
export const RESOURCE_PASSWORD_REGEXP = /^(?! )[a-zA-Z0-9-!@#$%^&.*]{2,29}$/;
export const RESOURCE_TEXT_REGEXP = /^(?! )[a-zA-Z0-9-]{2,29}[^\W]$/;
export const RESOURCE_URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
export const RESOURCE_STATE = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  PROVISIONING: 'PROVISIONING',
};
export const EVENT_DELIVERY = {
  ON_DEMAND: 'ON_DEMAND',
  NORMAL: 'NORMAL',
};
export const EVENT_ACCESS_TYPE = {
  RESTRICTED: 'RESTRICTED',
};
export const BOTS_PORTAL_SEARCH_SERVER_URL = 'https://bossportal.io/en-US/app/search';
export const EMAIL_MAX_IMPORT = 50;
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MAX_BULK_RESOURCES_LINES = 1000;
export const DEFAULT_ON_DEMAND_EVENT_DURATION = 120;
export const MAX_INT32 = 0x7fffffff;
export const NAV_MOBILE_MAXWIDTH = '600px';
export const ONE_MINUTE_MS = 60000;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_STEP = 1;