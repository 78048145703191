import { BOTS_PORTAL_SEARCH_SERVER_URL, RESOURCE_TYPE, MAX_BULK_RESOURCES_LINES } from '../../libs/constants';
import { exportFile } from '../../libs/csv-lib';

export const MULTI_SPEC_IMPORT_FILE_OPTION = 'MULTI_SPEC_OPTION';
export const CSV_IMPORT_TEMPLATE = {
  header: ['url', 'username', 'password'],
  data: [],
};

const isInvalid = (value) => !value || value === null || value === '';

const validateCredentialRow = (row) => {
  if (isInvalid(row.username)) {
    throw new Error(`"username" value is missed in row ${row.rowErrorIndex}`);
  }
  if (isInvalid(row.password)) {
    throw new Error(`"password" value is missed in row ${row.rowErrorIndex}`);
  }
};

const validateServerRow = (row) => {
  if (isInvalid(row.url)) {
    throw new Error(`"url" value is missed in row ${row.rowErrorIndex}`);
  }
};

function validateSingleSpecImportFileFields(parsedFile = [], specType) {
  if (parsedFile.length > MAX_BULK_RESOURCES_LINES) {
    throw new Error(`Your csv file has more than ${MAX_BULK_RESOURCES_LINES} lines`);
  }  
  parsedFile.forEach((row, idx) => {
    const rowErrorIndex = idx + 1;
    if (specType.toLowerCase() === RESOURCE_TYPE.CREDENTIALS) {
      validateCredentialRow({ ...row, rowErrorIndex });
    }
    if (specType.toLowerCase() === RESOURCE_TYPE.SERVER) {
      validateServerRow({ ...row, rowErrorIndex });
    }
    if (specType.toLowerCase() === RESOURCE_TYPE.URL) {
      validateServerRow({ ...row, rowErrorIndex });
    }
  });
}

export function validateResourcesImportFileFields(parsedFile, specType) {
  return validateSingleSpecImportFileFields(parsedFile, specType);
}

const getSearchSpecTraceQuery = (env = 'dev', groupId = '', specId = '', userId = '*') =>
  `index=bossng* host="serverless:aws:bossng-${env}" | spath severity | search severity=INFO | spath type | search type=RESOURCES | spath groupId | search groupId=${groupId}| spath specId | search specId=${specId}| spath consumerId | search consumerId=${userId}| table _time, trigger, model, consumerId, packId, groupId, specId, resourceId, slotId, state| sort -_time`;

// specSrn should have this form srn:spec::28GixU391T7cO9rXn5Uszs:botsv3phantom
export function generateSearchServerUrlBySpec(specSrn = '', userId) {
  const splittedSrn = specSrn.split(':');
  let groupId;
  let specName;

  try {
    groupId = splittedSrn[splittedSrn.length - 2];
  } catch (e) {
    groupId = '*';
  }

  try {
    specName = splittedSrn[splittedSrn.length - 1];
  } catch (e) {
    specName = '*';
  }

  return `${BOTS_PORTAL_SEARCH_SERVER_URL}/search?q=search%20${encodeURIComponent(
    getSearchSpecTraceQuery(process.env.REACT_APP_STAGE, groupId, specName, userId)
  )}`;
}

export const exportResourcesFile = (data, specName = '') => {
  const formatedData = data.map((d) => ({
    fields: [d.url, d.username, d.password],
  }));

  exportFile({ header: CSV_IMPORT_TEMPLATE.header, data: formatedData }, specName);
};
